<template>
	<v-sheet>
        <MwList storeModule="projects" :columns="headers">
			<template v-slot:item.created_at="item">
				<span v-if="item.created_at && item.created_at.toDate">
					{{ item.created_at.toDate().toLocaleDateString() }}
				</span>
			</template>
        </MwList>
    </v-sheet>
</template>

<script type="text/javascript">
import MwList from "@c/ui/MwList";
export default {
	name: "Projects",
    data: () => {
        return {
        }
    },
	computed: {
		headers() {
			return [
				{value: "internal_name", text: "Name", default: true},
				{value: "created_at", text: "Created At", default: true},
			];
		},
	},
	components: {
		MwList
	},
};
//
</script>
"
